
import { Component, Vue } from "vue-property-decorator";
import Encuesta from "@/components/Encuesta/Encuesta.vue";

@Component({
  components: {
    Encuesta
  }
})
export default class EncuestaView extends Vue {}
